/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyCh4jkNfJiIsknGuIn4MUmuzuctFzydR9Y",
  "appId": "1:512119880752:web:7d3176702c0768843001f5",
  "authDomain": "schooldog-i-lamar-ga.firebaseapp.com",
  "measurementId": "G-DR41V54FBY",
  "messagingSenderId": "512119880752",
  "project": "schooldog-i-lamar-ga",
  "projectId": "schooldog-i-lamar-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-lamar-ga.appspot.com"
}
